import React, { useState } from 'react';
import BillAnalysisIcon from '../../assets/images/sidebar-icon-bill-analysis.png';
import TranscriptIcon from '../../assets/images/sidebar-icon-transcript.png';
import Modal from '../../UI/Modal';
import PlayButtonIcon from '../TipSheetIcons/play-button';
import { CloseIcon } from '../TipSheetIcons/close-icon';
import styled from 'styled-components';

const SidebarWrapper = styled.div`
  display: flex;
  background-color: #0a819f;
  align-items: center;
  justify-content: space-evenly;
  padding: 1rem;
  margin-block-end: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

const SideBarContainer = styled.div`
  color: #ffffff;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 0.25rem;

  a {
    color: #ffffff;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    text-decoration: none;
  }
`;

const Cancel = styled.i`
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
`;

const TranscriptVideo = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  align-items: center;
`;

const TranscriptVideoIcon = styled.a`
  color: #0a819f;
`;

const TableHead = styled.thead`
  background-color: #0a819f;
  color: #ffffff;
  text-align: left;
`;

const TableData = styled.td`
  padding: 1rem;
`;

const TableRow = styled.tr`
  &:nth-child(odd) {
    background-color: #D9D9D9;
  }
`;

export default function Sidebar({ data }) {
  const {
    hearing_transcript,
    billanalysis_text,
    bill_name,
    tipsheet_type,
    video,
    jsonid,
  } = data;
  const [modalOpen, setModalOpen] = useState(false);
  const [contentType, setContentType] = useState(null);
  const hearingId = jsonid?.split('_')[2];

  var toHHMMSS = (secs) => {
    var sec_num = parseInt(secs, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor(sec_num / 60) % 60;
    var seconds = sec_num % 60;

    return [hours, minutes, seconds]
      .map((v) => (v < 10 ? '0' + v : v))
      .filter((v, i) => v !== '00' || i > 0)
      .join(':');
  };

  const formatTranscript = (transcript) => {
    if (!transcript) return;
    return (
      <table style={{ width: '100%' }} cellSpacing={0} cellPadding={0}>
        <TableHead>
          <tr>
            <th style={{ width: '10%', padding: '1rem' }}>Time</th>
            <th style={{ width: '20%', padding: '1rem' }}>Speaker</th>
            <th style={{ width: '70%', padding: '1rem' }}>Content</th>
          </tr>
        </TableHead>
        <tbody>
          {hearing_transcript.map((line) => {
            return (
              <TableRow
                data-starttime={line.start_time}
                data-endtime={line.end_time}
                tabIndex="0"
                key={line.start_time}
              >
                <TableData>{toHHMMSS(line.start_time)}</TableData>
                <TableData>
                  {line.speaker_first} {line.speaker_last}
                </TableData>
                <TableData>
                  <TranscriptVideo>
                    <span>{line.utterance}</span>
                    <TranscriptVideoIcon
                      href={`https://digitaldemocracy.calmatters.org/hearings/${hearingId}?t=${line.start_time}&f=${line.vid_file_id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PlayButtonIcon />
                    </TranscriptVideoIcon>
                  </TranscriptVideo>
                </TableData>
              </TableRow>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <SidebarWrapper>
      {bill_name?.toLowerCase() !== 'no bill discussed' && (
        <SideBarContainer
          onClick={() => {
            setModalOpen(true);
            setContentType('analysis');
          }}
        >
          <img src={BillAnalysisIcon} alt="Bill Analysis icon" />
          <div>Bill Analysis</div>
        </SideBarContainer>
      )}

      {video ? (
        <SideBarContainer>
          <a
            href={`https://digitaldemocracy.calmatters.org/hearings/${hearingId}`}
            target="_blank"
            rel="noreferrer"
          >
            <PlayButtonIcon />
            <div>Hearing Video</div>
          </a>
        </SideBarContainer>
      ) : null}

      {tipsheet_type !== 'bill' && (
        <SideBarContainer
          onClick={() => {
            setModalOpen(true);
            setContentType('transcript');
          }}
        >
          <img src={TranscriptIcon} alt="Transcript icon" />
          <div>Transcript</div>
        </SideBarContainer>
      )}

      <Modal
        style={{
          maxHeight: '600px',
          overflowY: 'scroll',
          padding: '2rem',
        }}
        showModal={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      >
        <Cancel
          onClick={() => {
            setModalOpen(false);
          }}
          style={{ cursor: 'pointer' }}
        >
          <CloseIcon />
        </Cancel>
        {contentType === 'analysis' ? (
          <div dangerouslySetInnerHTML={{ __html: billanalysis_text }} />
        ) : null}
        {contentType === 'transcript' ? (
          <div>{formatTranscript(hearing_transcript)}</div>
        ) : null}
      </Modal>
    </SidebarWrapper>
  );
}
