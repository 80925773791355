import React, { useState, useContext, useRef, useEffect } from 'react';
import logo from '../../assets/images/calmatters.jpg';
import { UserContext } from '../../hooks/UserContext';
import useLogout from '../../hooks/useLogout';
import { useLocation, Link } from 'react-router-dom';
import styled from 'styled-components';
import { ArrowContainer, Popover } from 'react-tiny-popover';

const HeaderWrapper = styled.header`
  padding: 1rem 4rem;
  background-color: #f3f9fa;

  @media (max-width: 1440px) {
    padding: 1rem 2rem;
  }

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const HeaderContainer = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const HeaderLogo = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  img {
    height: 3rem;
  }

  span {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.7;
  }

  @media (max-width: 768px) {
    span {
      display: none;
    }
  }
`;

const HeaderLink = styled.a`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #000000;
  text-decoration: none;
`;

const HeaderToggle = styled.button`
  display: none;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;

const HeaderNav = styled.nav`
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.7;

  a {
    text-decoration: none;
    color: #000000;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  @media (max-width: 768px) {
    display: ${({ hamburger }) => (hamburger === 'open' ? 'flex' : 'none')};
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #f3f9fa;
    z-index: 1;
    padding-block-end: 1rem;
  }
`;

const Tooltip = styled.button`
  background: #d9d9d9;
  border: none;
  cursor: pointer;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  top: -1px;
  position: relative;
`;

const TooltipButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;

  & img {
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    border: 4px solid #d9d9d9;
  }
`;

const VisuallyHidden = styled.button`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
`;

export default function Header() {
  const profilePicture = localStorage.getItem('profilePicture');
  const location = useLocation();
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const popoverContentRef = useRef(null);

  const toggleButton = () => setHamburgerOpen(!hamburgerOpen);
  const { user } = useContext(UserContext);
  const { logoutUser } = useLogout();

  useEffect(() => {
    if (isPopoverOpen && popoverContentRef.current) {
      popoverContentRef.current.focus();
    }
  }, [isPopoverOpen]);

  return (
    location.pathname !== '/login' &&
    location.pathname !== '/signup' &&
    location.pathname !== '/interests' && (
      <HeaderWrapper>
        <HeaderContainer>
          <HeaderLogo>
		  	<HeaderLink as={Link} to="/">
              <img src={logo} alt="AI Tip Sheets by CalMatters" />
              <span>AI Tip Sheets</span>
            </HeaderLink>
            <HeaderToggle type="button" onClick={toggleButton}>
              ☰
            </HeaderToggle>
          </HeaderLogo>
          <HeaderNav hamburger={hamburgerOpen ? 'open' : undefined}>
            <Link to="/archive">Archive</Link>
            <Link to="/about">About</Link>
            <Link to="/profile">Profile</Link>
            {user ? (
              <Popover
                isOpen={isPopoverOpen}
                positions={['bottom']}
                onClickOutside={() => setPopoverOpen(false)}
                containerStyle={{ zIndex: 1 }}
                content={({ position, childRect, popoverRect }) => (
                  <ArrowContainer
                    position={position}
                    childRect={childRect}
                    popoverRect={popoverRect}
                    arrowColor={'#D9D9D9'}
                    arrowSize={10}
                  >
                    <Tooltip
                      type="button"
                      onClick={logoutUser}
                      ref={popoverContentRef}
                    >
                      Sign Out
                      <VisuallyHidden
                        type="button"
                        onFocus={() => setPopoverOpen(false)}
                      >
                        Close Popover
                      </VisuallyHidden>
                    </Tooltip>
                  </ArrowContainer>
                )}
              >
                <TooltipButton
                  type="button"
                  onClick={() => setPopoverOpen(!isPopoverOpen)}
                >
                  {profilePicture && (
                    <img
                      src={profilePicture}
                      referrerPolicy="no-referrer"
                      crossOrigin="anonymous"
                      alt="Profile"
                    />
                  )}
                </TooltipButton>
              </Popover>
            ) : null}
          </HeaderNav>
        </HeaderContainer>
      </HeaderWrapper>
    )
  );
}
