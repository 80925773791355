import React, { useEffect, useState } from "react";
import "../Story/Story.Style";
import "./TipSheet.css";
import axios from "axios";

// This component is making an API call that should be unnecessary, since
// Story.js can pass the required JSON data. The component is also not generating
// any visible elements.
export default function TipSheet_Participant(props) {
    const [dataTopic, setDataTopic] = useState([]);

    const fetchData = () => {

        axios
            .get(props.api_url)
            .then((response) => {
                setDataTopic(response.data.tipsheet_html_segments);
            })
            .catch((err) => console.log(err));
    };

    function Sub_Index(item) {
        var subject_index = item.map((element) =>
            element.indexOf("Speaker Participation Chart (seconds)")
        );
        var subject_index_copy = subject_index;
        var subject_index_num = subject_index_copy.filter((item) => item >= 0);
        subject_index_num = subject_index.indexOf(subject_index_num[0]);
        return subject_index_num;
    }

    useEffect(() => {
        fetchData();
        let temporary = document.createElement("div");
        let wrap = document.querySelector(".attendance_participation");
        temporary.innerHTML =
            dataTopic[Sub_Index(dataTopic)] === undefined
                ? null
                : dataTopic[Sub_Index(dataTopic)];
        temporary.classList = " outline";
        wrap.appendChild(temporary);
    }, []);

    return <div className="attendance_participation"></div>;
}
