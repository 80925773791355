import React, { useState, useEffect } from 'react';
import profile from '../../../../hooks/Profile';
import LawMakerComponent from './LawMakerComponent';
import swal from 'sweetalert';

export default function CoverageTopic(props) {
  const {
    S,
    allTags,
    userInput,
    handleCheckboxClick,
    user,
    setUserInput,
    changeSelected,
    setUserContext,
  } = props;

  const { updateCoverageTopics, updateProfileWithoutPush } = profile();
  const [TopicTags, setTopicTags] = useState([]);
  const [Lawmakers, setLawmakers] = useState([]);

  const [getCoverageTopicsState, setCoverageTopicsState] = useState([]);
  const [getnew, setnew] = useState(false);
  const [locationCoveredIndexToRemove, setLocationCoveredIndexToRemove] =
    useState(-1);

  const [pause, setPause] = useState(false);

  const ArrayToString = (array) => {
    return array.toString();
  };
  const StringToArray = (string) => {
    if (string) {
      return string.split(',');
    }
    return [];
  };
  useEffect(() => {
    if (!pause) {
      setTopicTags(StringToArray(user.user_topic_tags));
      if (user.user_lawmakers) setLawmakers(JSON.parse(user.user_lawmakers));
      let temp = user.user_location_covered;
      if (temp) {
        if (temp.length > 1) {
          setCoverageTopicsState(temp);
        } else {
          setnew(true);
        }
      } else {
        setnew(true);
      }
      if (user) {
        let user_interests = StringToArray(user.user_interests.toLowerCase());
        setUserInput(user_interests);
      }
    }
  }, [user]);
  useEffect(() => {
    if (getnew) {
      let currentstate = getCoverageTopicsState;
      let newrow = {
        id: null,
        state_name: '',
        county: '',
        zip_code: '',
      };
      currentstate.push(newrow);
      setCoverageTopicsState(currentstate);
      setnew(false);
    }
  }, [getnew]);

  useEffect(() => {
    if (locationCoveredIndexToRemove != -1) {
      let currentstate = getCoverageTopicsState;
      currentstate.splice(locationCoveredIndexToRemove, 1);
      setCoverageTopicsState(currentstate);
      setLocationCoveredIndexToRemove(-1);
    }
  }, [locationCoveredIndexToRemove]);

  const getNewLocationCovered = () => {
    let i = 0;
    let list = [];
    while (true) {
      if (document.getElementsByName('state_name__' + i).length > 0) {
        let id = document.getElementsByName('location_covered_id' + i)[0].value;
        if (!id) {
          id = null;
        }
        let newrow = {
          id: id,
          state_name: document.getElementsByName('state_name__' + i)[0].value,
          county: document.getElementsByName('county__' + i)[0].value,
          zip_code: document.getElementsByName('zip_code__' + i)[0].value,
        };
        list.push(newrow);
      } else {
        break;
      }
      i++;
    }
    return list;
  };

  const getInterests = () => {
    return ArrayToString(
      Array.prototype.slice
        .call(document.getElementsByName('interest_checkbox[]'))
        .filter((ch) => ch.checked == true)
        .map((e) => e.value)
    );
  };
  const updateAll = async () => {
    let user_interests = getInterests();
    let user_topic_tags = ArrayToString(TopicTags);
    let user_lawmakers = JSON.stringify(Lawmakers);

    let tempUSer = user;

    tempUSer.user_interests = user_interests;
    tempUSer.user_topic_tags = user_topic_tags;
    tempUSer.user_lawmakers = user_lawmakers;

    let user_location_covered = getNewLocationCovered();
    let result1 = await updateCoverageTopics(user_location_covered);
    let result = await updateProfileWithoutPush(tempUSer);
    if (result && result1) {
      swal({
        title: 'Update',
        text: 'Profile updated successfully!',
        icon: 'success',
      }).then((value) => {
        setUserContext('no');
        changeSelected('Account_Information');
      });
    } else {
      swal('Profile Update Failed');
    }
  };

  return (
    <S.Body>
      <S.BodyBox className="BodyBox">
        <S.BodyBoxTitle className="BodyBoxTitle">INTERESTS</S.BodyBoxTitle>
        <S.FilterBoxes className="FilterBoxes">
          {allTags.map(({ svg, icon }, i) => {
            let ta_display =
              icon === 'public_safety'
                ? 'Public Safety'
                : icon.substring(0, 3) === 'geo'
                ? icon.substring(3).replace(/_/g, ' ').toUpperCase()
                : icon[0].toUpperCase() + icon.substring(1);
            return (
              <label className="check-container" key={i}>
                {ta_display}
                <input
                  value={ta_display}
                  className="interest_checkbox_class"
                  name="interest_checkbox[]"
                  type="checkbox"
                  checked={userInput.includes(icon) === true}
                  onChange={() => {
                    handleCheckboxClick(icon);
                  }}
                />
                <span className="checkmark"></span>
              </label>
            );
          })}
        </S.FilterBoxes>
      </S.BodyBox>
      <LawMakerComponent Lawmakers={Lawmakers} setLawmakers={setLawmakers} />
      <S.BodyBox className="BodyBox">
        <div style={{ 'text-align': 'center' }}>
          <button
            type="button"
            className="button-account-information"
            onClick={updateAll}
          >
            Save All
          </button>
        </div>
      </S.BodyBox>
    </S.Body>
  );
}
