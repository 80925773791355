const PlayButtonIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={26}
    height={25}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M13 0C5.819 0 0 5.595 0 12.5S5.819 25 13 25s13-5.595 13-12.5S20.181 0 13 0Zm6.065 13.71L9.839 18.8c-.828.444-1.871-.126-1.871-1.058V7.258c0-.927 1.038-1.502 1.871-1.058l9.226 5.393c.86.463.86 1.658 0 2.117Z"
    />
  </svg>
);
export default PlayButtonIcon;
