const QuestionMarkIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={15}
    fill="none"
    {...props}
  >
    <path
      fill="#028AD1"
      d="M16 7.5c0 4.143-3.582 7.5-8 7.5s-8-3.357-8-7.5C0 3.36 3.582 0 8 0s8 3.36 8 7.5ZM8.215 2.48c-1.758 0-2.88.694-3.76 1.928a.349.349 0 0 0 .088.492l1.119.795c.168.12.407.091.538-.064.576-.685.97-1.082 1.848-1.082.659 0 1.474.397 1.474.996 0 .453-.399.686-1.05 1.028-.758.399-1.762.895-1.762 2.137v.12c0 .201.173.364.387.364h1.806c.214 0 .387-.163.387-.363v-.04c0-.861 2.684-.897 2.684-3.226 0-1.755-1.941-3.085-3.76-3.085ZM8 9.98c-.818 0-1.484.624-1.484 1.391S7.182 12.762 8 12.762s1.484-.624 1.484-1.391S8.818 9.98 8 9.98Z"
    />
  </svg>
)
export default QuestionMarkIcon
