export function CloseIcon() {
	return (
		<svg
			fill="none"
			height="17"
			viewBox="0 0 21 20"
			width="17"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M2 1.50274 19 18.5027m-17-.108L19 1.39468"
				stroke="#000000"
				strokeLinecap="round"
				strokeWidth="2.6"
			/>
		</svg>
	);
}
