import React from "react";
import background from "../../assets/images/background.jpg";
import arnolds from "../../assets/images/logos/Arnold-Ventures_logo.png";
import calmatters from "../../assets/images/logos/calmatters.png";
import calpoly from "../../assets/images/logos/calpoly_logo.png";
import iatpp from "../../assets/images/logos/IATPP_logo.png";
import knight from "../../assets/images/logos/knight_logo.png";
import miami from "../../assets/images/logos/university-of-miami_logo.png";
import graz from "../../assets/images/logos/TUGraz.png";
import tenup from "../../assets/images/logos/10up-logo.png";
import styled from "styled-components";
import "./About.css";

const AboutWrap = styled.div`
    background-color: #e5e5e5;
    min-width: 300px;
`;


var logos = [calmatters, arnolds, knight, iatpp, graz, miami, calpoly, tenup];
var sm_logos = [miami,calpoly];
var reduce_logos = [iatpp];
function renderLogos(logos) {
    return logos.map((item) => {
        if (sm_logos.includes(item)){
            return (<img alt = "logo" style={{height: 60 + 'px', paddingTop:0 + 'px'}} src = {item} />);
        } else if (reduce_logos.includes(iatpp))
            return (<img alt = "logo" style={{height: 45 + 'px'}} src = {item} />);
        else
            return (<img alt = "logo" src = {item} />);
    });

}
export default function About() {
    return (
        <AboutWrap>
            <div className="backgroundDiv" style={{backgroundImage: `url(${background})`}}>
                <div className="about-box">
                    <p>AI Tip Sheets is part of <a href={"https://digitaldemocracy.calmatters.org/about-digital-democracy"}>
                        Digital Democracy</a> by CalMatters. It was developed at the Cal Poly Institute for Advanced
                        Technology and Public Policy over several years in collaboration with CalMatters, Cal Poly, 10UP,
                        Graz University of Technology and University of Miami. Major funding provided by CalMatters, Arnold Ventures and Knight Foundation.
                    </p>
                    <div className="logos-box">{renderLogos(logos)}</div>
                </div>
            </div>
        </AboutWrap>
    );
}
