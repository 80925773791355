import styled from 'styled-components';

export const StoryWrapper = styled.div`
  padding: 2rem 4rem;
  background-color: #ffffff;

  @media (max-width: 1440px) {
    padding: 2rem;
  }

  @media (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

export const StoryContainer = styled.div`
  max-width: 1440px;
  margin: 0 auto;
`;

export const Layout = styled.div`
  background-color: #e5e5e5;
  grid-template-columns: 1fr 15fr;
  grid-template-areas: 'aa bb';
  min-height: 1000px;
  max-height: auto;
`;
export const SidebarWrap = styled.div`
  grid-area: aa;
  height: auto 100vh;
`;

export const DetailView = styled.div`
  grid-area: bb;
  background-color: white;
  min-width: 200px;
  box-sizing: border-box;
  grid-template-columns: 7fr 5fr;
  grid-template-rows: auto auto auto auto auto auto auto auto auto auto;

  //grid-template-areas: "xx yy";

  /* grid-gap: 30px;
    justify-items: center;
    align-content: center;
    padding: 40px; */

  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
  }
`;
export const Bar1 = styled.div`
  //grid-area: xx;
  grid-column: 1 / span 2;
  margin: 10px;
  margin-bottom: 0;
  font-size: 25px;
  overflow-y: hidden;
  min-height: 200px;
  max-height: none;
`;

export const Bar2 = styled.div`
  //grid-area: xx;
  display: flex;
  flex-direction: column;

  background-color: white;
  border-color: black;
  font-size: 25px;
  margin: 10px;
  justify-self: stretch;

  overflow-y: hidden;
  min-height: 200px;
  max-height: auto;
`;

export const OpenModel = styled.img`
  height: 25px;
  width: 25px;
`;
export const Headline = styled.p`
  margin-left: 30px;
  padding-left: 10px;
  font-weight: 600;
  margin-block-start: 0px;
  margin-block-end: 0px;
`;
export const Para = styled.p`
  margin-left: 30px;
  padding-left: 10px;
  font-weight: 400;
  font-size: 15px;
  padding-top: 5px;
  margin-block-start: 0px;
  margin-block-end: 0px;

  .floatLeft {
    float: left;
  }
  .floatRight {
    float: right;
  }
  white-space: normal;
`;

export const TipSheetBox = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;
  border: 1px solid #e5e5e5;
  border-radius: 0.5rem;
  margin: 2rem 0;
`;

export const Bar3 = styled.div`
  //grid-area: yy;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-color: black;
  font-size: 25px;
  justify-self: stretch;
  overflow-y: hidden;
  min-height: 200px;
  max-height: auto;
`;

export const Dots = styled(({ className, children }) => (
  <span className={`dots ${className}`}>{children}</span>
))``;

export const Video = styled.video`
  grid-area: k;
  height: auto;
  // height: 90%;
  cursor: pointer;
`;
export const BoxTitle = styled.h3`
  padding: 20px 20px 10px 20px;
  margin: 0;
  overflow: hidden;
  margin-left: 20px;
`;

export const BoxNote = styled.div`
  padding: 10px 20px 10px 20px;
  font-size: 12px;
  margin: 0;
  overflow: hidden;
  margin-left: 20px;
`;

export const BoxImage = styled.img`
  width: 60px;
  cursor: pointer;
  padding-inline: 23px;
`;

export const ImageWrap = styled.div`
  display: grid;
  flex-direction: column;
  background-color: #ffffff;
  font-size: 25px;
  margin: 2rem 0;
  grid-area: l;
  justify-self: stretch;
  border: 1px solid #e5e5e5;
  border-radius: 0.5rem;
`;
export const Images = styled.div`
  padding: 20px 20px 10px 20px;
  min-height: 245px;
  font-size: 12px;
  //text-align:center;
  justify-self: center;
  //cursor: pointer;
`;
export const ImageSliderWrap = styled.div`
  height: 100%;
  position: relative;
  display: grid;
  //align-items: center;
  //justify-items: center;
  place-items: center;
`;
export const Cancel = styled.i`
  position: absolute;
  top: 30px;
  right: 30px;
`;
export const ImageSlideToggle = styled.i`
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0px;
`;

export const Article = styled.div``;

export const InterestTag = styled.div`
  float: right;
  border: 1px solid black;
  border-radius: 4px;
  font-size: 12px;
  padding: 0 7px;
  margin: 16px 5px;
  display: grid;
  grid-template-columns: 20px auto;
`;

export const InterestTagIcon = styled.img`
  width: 20px;
  margin-top: 3px;
`;

export const InterestTagLabel = styled.div`
  float: right;
  padding: 5px;
  font-weight: bold;
`;

export const TipSummaryBlock = styled.div`
  display: inline-block;
`;

export const VideoHearingLink = styled.div`
  cursor: pointer;
  width: 40%;
  display: inline-block;
  //float: left;
  padding-top: 10px;
  display: grid;
  grid-template-columns: 80px auto;
`;

export const SummaryVideo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const VideoHearingIcon = styled.img`
  width: 75px;
`;
export const VideoHearingLabel = styled.div`
  font-size: 15px;
  padding: 14px 8px;
  font-weight: 500;
`;

export const VideoBlock = styled.div`
  padding: 3px;
  cursor: pointer;
`;

export const Block = styled.div`
  width: 100%;
`;
export const Span = styled.span``;

export const Caption = styled.span`
  font-size: 13px;
  padding-left: 20px;
`;
