import styled from 'styled-components';

export const DatePickerContainer = styled.div`
  color: #ffffff;
  display: flex;
  gap: 1rem;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const DatePickerInput = styled.input`
  margin-left: 2px;
`;
