import React from "react";
import { Chart } from "react-google-charts";
import "./ChartTable.css";

const default_options = {
                    allowHtml: true,
                    cssClassNames: { tableCell: "cell", width: "width" },
                    showRowNumber: false,
                    explorer: {axis: 'vertical'},
                    page: "enable",
                    pageSize: 10,
                }

const ChartTable = (props) => {
    // overwrites with any option passed in
    let final_options = default_options;
    if (props.options && Object.keys(props.options).length > 0) {
        Object.keys(props.options).forEach((key) => {
            final_options[key] = props.options[key];
        })
    }
    return (
        <div className="Chart-container">
            <Chart
                width="100%"
                chartType="Table"
                loader={<div>Loading Chart</div>}
                data={props.dataTable}
                options={final_options}
                rootProps={{ "data-testid": "1" }}
                formatters = {props.formatters}
            />
        </div>
    );
};
export default ChartTable;
