import { useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";
import { UserContext } from "./UserContext";

export default function useAuth() {
    const navigate = useNavigate();
  let location = useLocation();
  const { user, setUser } = useContext(UserContext);
  const [error, setError] = useState(null);
  const getUserContext = async () => {
    return user;
  };
  //set user
  const setUserContext = async (redirectUrl = "/") => {
    let axiosT = axios.create({
      baseURL: `${process.env.REACT_APP_FEATURED_API}auth/profile/`,
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`, // token has user data so /profile knows which (signed in) user to get
      },
    });
    return await axiosT
      .get("/") // previously 'profile/'
      .then((res) => {
        let currentUser = res.data.currentUser;
        setUser(currentUser);
        if (redirectUrl !== "no") {
          navigate(redirectUrl);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //register user
  const registerUser = async (data) => {
    let axiosT = axios.create({
      baseURL: `${process.env.REACT_APP_FEATURED_API}auth/user/`,
    });
    const { email } = data;
    return axiosT
      .post(`signup/`, { email })
      .then(async (res) => {
        navigate("/login")
      })
      .catch((err) => {
        formatErrors(err);
      });
  };

  //login user
  const loginUser = async (credential, clientId) => {
    let axiosT = axios.create({
      baseURL: `${process.env.REACT_APP_FEATURED_API}auth/user/`,
    });

    return axiosT
      .post("verifytoken/", { // validate google oauth token
        credential,
        clientId
      })
      .then(response => {
        if (response.status === 200) {
          const email = response.data.email;
          return axiosT.post("signin/", { email });
        } else {
          throw new Error("OAuth verification failed");
        }
      })
      .then(async (res) => {
        let token = res.data.token;
        localStorage.setItem("token", token); // after signin, get token from response & store in localStorage
        const { from } = location.state || { from : {pathname: '/'}}; // get redirect url or default to homepage
        await setUserContext(from.pathname); // then try to set profile data and redirect
      })
      .catch((err) => {
        formatErrors(err);
      });
  };

  const changePassword = async (data) => {
    const { old_password, new_password, confirm_new_password } = data;

    let axisoT = axios.create({
      baseURL: `${process.env.REACT_APP_FEATURED_API}`,
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    });
    return axisoT
      .put("changepassword/", {
        old_password,
        new_password,
        confirm_new_password,
      })
      .then(async (res) => {
        let token = res.data.token;
        localStorage.setItem("token", token);
        return true;
      })
      .catch((err) => {
        formatErrors(err);
      });
  };
  const toTitleCase = (phrase) => {
    return phrase
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const formatErrors = (err) => {
    let messages = "";
    try {
      let keys = Object.keys(err.response.data);
      keys.forEach((bk) => {
        let errorL = err.response.data[bk];
        errorL.forEach((ck) => {
          let key = "";
          if (bk !== "non_field_errors") {
            key = toTitleCase(bk.replaceAll("_", " ")) + " : ";
          }
          messages += key + ck + "<br>";
        });
      });
    } catch (error) {
      messages = "Somthing bad happen";
    }
    document.getElementById("form-error").innerHTML = messages;
  };
  return {
    getUserContext,
    setUserContext,
    registerUser,
    loginUser,
    changePassword,
    error,
  };
}
