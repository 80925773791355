import React from "react";
import { Chart } from "react-google-charts";

const ChartPie = (props) => {
  return (
    <div className="Chart-container">
      <Chart
        width={"auto"}
        height={"auto"}
        chartType="PieChart"
        loader={<div>Loading Chart</div>}
        data={props.dataPie}
        options={{
          chartArea: {
            height: "95%",
            top: "0%",
            width: "95%",
            left: "25%",
          },
            sliceVisibilityThreshold: .05,
            is3D: true,
        }}
        rootProps={{ "data-testid": "1" }}
      />
    </div>
  );
};
export default ChartPie;
