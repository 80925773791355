import React, { useState, useEffect,useContext } from "react";
import Stories from "../Stories/Stories";

import Filter from "../Filter/Filter";
import axios from "axios";
import { UserContext } from '../../hooks/UserContext';
import background from "../../assets/images/background.jpg";
import { useNavigate, useLocation } from 'react-router-dom';
import {getWeek, getDay, getYear} from "date-fns";
const FEATURED_API = `${process.env.REACT_APP_FEATURED_API}`;
const MIN_NUMBER_TILES_VISIBLE = 30;

export default function Home() {
  const { user } = useContext(UserContext);
  const [tags, setTags] = useState([]);
  const [timeFilter, setTimeFilter] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [allStories, setAllStories] = useState([]);
  const [sortBy, setSortBy] = useState("score");
  const [lawmakers, setLawmakers] = useState([]);
  const [exclusions, setExclusions] = useState([]);

  // handle redirects upon expired token API requests
  let location = useLocation();
  const navigate = useNavigate();
  const handleExpiredToken = () => {
    console.log("Expired session token, redirecting to login.")
    localStorage.removeItem('token');
	navigate('/login', { state: { from: location } });
  };

  const fetchAllStories = () => {
    let axiosT = axios.create({
      baseURL: `${process.env.REACT_APP_FEATURED_API}`,
      headers: {
        'Authorization': `JWT ${localStorage.getItem('token')}`
      }
    });
    axiosT
      .get("/")
      .then((res) => {
        setAllStories(res.data.files);
      })
      .catch((err) => {
        console.log(err);
        handleExpiredToken();
      });
  };

  // This hook only runs once which gets all the stories and puts it in the allStories
  useEffect(() => {
    fetchAllStories(FEATURED_API);
  }, []);

  const [stories, setStories] = useState([]);

  const countMatches = (searchTerms, transcriptUtterances) => {
    let count = 0;
    searchTerms.forEach((s) => {
      const regex = new RegExp(s.toLowerCase(), "g");
      count += ((transcriptUtterances.toLowerCase() || "").match(regex) || [])
        .length;
    });

    return count;
  };

  const getAllLemmas = (str) => {
    var lemmatizer = require("wink-lemmatizer");
    let lem = [];
    lem.push(lemmatizer.adjective(str));
    lem.push(lemmatizer.verb(str));
    lem.push(lemmatizer.noun(str));
    return [...new Set(lem)];
  };

  // if there is anything in the searchterm and if it does not match then do not render all articles
  // fix search when two+ terms are given
  const userFilteredStories = (stories) => {
    let storyList = [];
    // include stories with at least one of the user's lawmakers (logical or)
    if (lawmakers.length > 0) {
      stories.forEach((file) => {
        let canAdd = false
        try {
          lawmakers.forEach((lawmaker) => {
            file.tipsheet_tile_json.personas.forEach((persona) => {
              if (persona.p_type.includes("Legislator")) {
                if (persona.pid === lawmaker.pid) {
                  canAdd = true;
                }
              }
            });
          })
        }
        catch (e) {
          console.log(e)
          canAdd = true
        }
        if (canAdd) storyList.push(file);
      });
    } else {
      stories.forEach((file) => {
        storyList.push(file);
      });
    }
    return storyList;
  }

  const getHearingDate = (tipsheet) => {
    //tile date is like "2024-07-02" for "July 2, 2024"
    const dateSegments = tipsheet.tile_date.split('-');
    return new Date(dateSegments[0],Number(dateSegments[1])-1,dateSegments[2]);
  };

  const isWithinLastXDays = (days, dateString) => {
    const [year, month, day] = dateString.split("-").map(Number);
    let date = new Date(year, month - 1, day);
    const xDaysAgo = new Date();
    xDaysAgo.setDate(xDaysAgo.getDate() - days);
    return date >= xDaysAgo;
  };

  // check to see if term is present in headline, bill_numb, tile_id, committee or the full names of every author
  const searchHighAttributes = (file, term) => {
    let attributes = [file.headline, file.tile_bill_num, file.id, file.tile_committee];
    return (attributes.some(item => item.toLowerCase().includes(term.toLowerCase()))) ||
        (file.tipsheet_tile_json.hasOwnProperty('bill_authors') &&
            (file.tipsheet_tile_json.bill_authors.some(author =>
                (author.first+' '+author.last).toLowerCase().includes(term.toLowerCase()))));
  }

  const isExcluded = (tile, exclusionsList) => {
    if (exclusionsList.length === 0)
      return false;
    return ((exclusionsList.includes("appropriations") && tile.tile_committee?.toLowerCase().includes("committee on appropriations"))
        || ((exclusionsList.includes("floor")) && tile.tile_committee?.toLowerCase().includes(" floor"))
        || ((exclusionsList.includes("informational")) && tile.tile_bill_num?.toLowerCase().includes("informational"))
        || ((exclusionsList.includes("zero")) && tile.tipsheet_tile_json?.score === 0)
    );
  }
  const fetchStories = () => {
    let userFilteredFiles = allStories;
    let finalStoryList = [];

    if (timeFilter > 0) {
      userFilteredFiles = userFilteredFiles.filter((x) =>
        isWithinLastXDays(timeFilter, x["tile_date"])
      );
      if (userFilteredFiles.length < MIN_NUMBER_TILES_VISIBLE){
          userFilteredFiles = allStories
              .toSorted((a, b) => getHearingDate(b) - getHearingDate(a))
              .slice(0,Math.min(MIN_NUMBER_TILES_VISIBLE,allStories.length));
      }
    }

    userFilteredFiles = userFilteredStories(userFilteredFiles).filter(story => !isExcluded(story,exclusions));

    if (tags.length === 0 && searchTerm === "") {
      finalStoryList = userFilteredFiles;      
    } else {
      let storyList = [];      
      // what i'll need to do: have a storage for ordered storylist so maybe a hashmap with the weight beside it
      // so if education shows up 50 in transcript and 2 times in headline => weight : 50+2*2 (2:1 ratio)
      // so if education shows up 0 in transcript and 3 times in headline => weight : 0+3*2 (2:1 ratio)
      // then rank in descending order.
      // also make sure to account for common suffixes for the searchterm [-ing, -es, -s, -ed, ..]
      // also if the word has the suffixes strip them and then search
      // use syllables.estimate to make sure we don't match 's for sing'
      // if searchterm is one word with no spaces then do suffix checks
      // if it does have suffix and no of syllables > 1 then also add word without suffix in searchterm
      userFilteredFiles.forEach((file) => {
        // Apply logic for interaction between tag and seachTerm here
        if (searchTerm !== "" && tags.length === 0) {
          let searchTerms = getAllLemmas(searchTerm);
          if (file.tipsheet_tile_json.billanalysis_text) {
            //no transcripts in tiles anymore, switching to billanalysis
            //-Foaad

            let billanalysis = file.tipsheet_tile_json.billanalysis_text.toLowerCase();
            const count_m = countMatches(searchTerms, billanalysis);
            storyList.push([
              file,
              count_m,
            ]);
          }

          if (searchHighAttributes(file,searchTerm)) {
            let index = storyList.findIndex(
              (e) => e[0].id === file.id
            );
            if (index !== -1) {
              storyList[index][1] += 2;
            } else {
              storyList.push([file, 2]);
            }
          }
          // sorting the list in descending order
          storyList.sort(function (a, b) {
            return b[1] - a[1];
          });
        }

        // case #2
        else if (searchTerm === "" && tags.length !== 0) {
          if(file.tipsheet_tile_json.tags){
            file.tipsheet_tile_json.tags.forEach((t) => {
              if (tags.includes(t.tagname.toUpperCase())) {
                // This adds file to bexisting stories
                storyList.push([file, 1]);
              }
            });
          }
        }

        // case #3
        else if (searchTerm !== "" && tags.length !== 0) {
          file.tipsheet_tile_json.tags.forEach((t) => {
            if (tags.includes(t.tagname.toUpperCase())) {
              let searchText = ""
              if (file.tipsheet_tile_json.billanalysis_text) {
                //let transcriptUtterances = "";
                //commenting this out because for utterances, it was an array, so it was consolidating them
                // first but for bill analysis, it's just one text
                //file.tipsheet_tile_json.billanalysis_text.forEach((entry) => {
                //  transcriptUtterances = transcriptUtterances.concat(
                //    " ",
                //    entry.utterance.toLowerCase()
                //  );
                searchText = file.tipsheet_tile_json.billanalysis_text.toLowerCase();
                let searchTerms = getAllLemmas(searchTerm);

                storyList.push([
                  file,
                  countMatches(searchTerms, searchText),
                ]);
              }
            }
            if (searchHighAttributes(file,searchTerm)) {
                let index = storyList.findIndex(
                  (e) => e[0] === searchTerm.toLowerCase()
                );
                if (index !== -1) {
                  storyList[index] = [file, storyList[index][1] + 2];
                } else {
                  storyList.push([file, 2]);
                }
              }
              storyList.sort(function (a, b) {
                return b[1] - a[1];
              });
          });
        }
      });

      storyList.forEach((t) => {
        // only push to final list if the number of occurrences is more than 1
        if (t[1] !== 0) {
          finalStoryList.push(t[0]);
        }
      });           
    }

    finalStoryList = finalStoryList.map(story => {
      let storyDate = getHearingDate(story);
      storyDate.setHours(-24 * getDay(storyDate));
      story['week_str'] = storyDate.toLocaleDateString();
      story['week'] = getWeek(storyDate) + (getYear(storyDate)-2010)*52; //number of weeks since 2010
      return story;
    });

    switch (sortBy) {
      case "chrono":
        setStories(finalStoryList.toSorted((a, b) => getHearingDate(b) - getHearingDate(a)));
        break;
      case "score":
        setStories(finalStoryList.toSorted((a, b) =>
            b.tipsheet_tile_json.score - a.tipsheet_tile_json.score,0));
        break;
      default:
        setStories(finalStoryList);
    }
  };

  useEffect(() => {
    fetchStories(FEATURED_API);
  }, [tags, searchTerm, timeFilter, allStories, sortBy, lawmakers, exclusions]);

  return (
    <div className="backgroundDiv" style={{ backgroundImage: `url(${background})`, backgroundRepeat: "repeat"}}>
      <Filter
        setTags={setTags}
        timeFilter={timeFilter}
        setTimeFilter={setTimeFilter}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        sortBy={sortBy}
        setSortBy={setSortBy}
        lawmakers={lawmakers}
        setLawmakers={setLawmakers}
        setExclusions={setExclusions}
      />
      <Stories stories={stories} lawmakers={lawmakers} />
    </div>
  );
}
