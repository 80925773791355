import styled from 'styled-components';

export const FilterWrap = styled.div`
  background-image: linear-gradient(to right, #0e4c60, #4395b7);
  position: relative;
  display: flex;
  padding: 1rem 4rem;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    padding: 1rem;
    flex-direction: column;
  }
`;

export const FilterButton = styled.button`
  width: 120px;
  border-radius: 5px;
  padding-left: 10px;
  margin: 0px;
  border: 1px solid white;
  outline: none;
  align-items: center;
  height: 30px;
  color: white;
  background-color: #0e4c60; /*#4c4b4c;*/

  display: grid;
  grid-template-columns: 2fr 1fr;
  justify-items: center;
`;

export const FilterClearButton = styled.button`
  width: 60px;
`;

export const FilterTextBox = styled.input`
  background-color: #0e4c60;
  border: 1px solid white;
  height: 25px;
  border-radius: 5px;
  outline: none;
  color: white;
  resize: horizontal;
  padding-left: 5px;
`;

export const FilterDropDown = styled.div`
  position: absolute;
  border: 1px solid white;
  background-color: #0e4c60;
  display: grid;
  grid-gap: 10px;
  padding: 10px;
  border-radius: 5px;
  top: 30px;
  left: 0;
  z-index: 10;

  @media (max-width: 600px) {
    width: calc(100% - 2rem);
  }
`;

export const FilterBoxes = styled.div`
  display: grid;
  grid-template-rows: repeat(15, 20px);
  grid-template-columns: 150px 150px;
  grid-gap: 5px;
`;

export const ExclusionSection = styled.div`
  display: grid;
  grid-template-rows: repeat(3, 20px);
  grid-gap: 5px;
`;

export const SearchButton = styled.button`
  background: #0e4c60;
  color: white;
  border: solid 1px white;
  border-radius: 8px;
  height: 23px;
  width: 80px;
  font-size: 14px;
  justify-self: center;
  &:hover {
    background-color: darkgray;
    transform: scale(1.2);
  }
`;

export const ClearButton = styled.button`
  background: #0e4c60;
  color: white;
  border: solid 1px white;
  border-radius: 8px;
  height: 23px;
  width: 80px;
  font-size: 14px;
  justify-self: center;
  &:hover {
    background-color: darkgray;
    transform: scale(1.2);
  }
`;

export const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-self: center;
  gap: 10px;
`;

export const MenuItem = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 13px 20px 1fr;
  grid-gap: 4px;
`;
export const SmWrap = styled.div`
  align-items: center;
  position: relative;
  display: flex;
  gap: 0.5rem;

  @media (max-width: 600px) {
    flex-direction: column;
    width: 100%;
  }
`;
export const Checkbox = styled.input``;

export const Tags = styled.span`
  color: ${(props) => (props.active ? '#FCD70B' : '#b7b7b8')};
`;

export const Img = styled.img`
  height: 20px;
  filter: ${(props) =>
    props.active
      ? `brightness(0) saturate(100%) invert(84%) sepia(53%) saturate(2464%) hue-rotate(354deg) brightness(110%) contrast(98%)`
      : ' '};
`;

export const IconUp = styled.i`
  padding-top: 6px;
`;

export const IconDown = styled.i``;

export const SelectionButton = styled.button`
  width: 130px;
  border-radius: 5px;
  padding: 0px;
  margin: 0px;
  border: 1px solid white;
  outline: none;
  align-items: center;
  height: 30px;
  color: white;
  background-color: #0e4c60;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const StateTags = styled.div`
  display: grid;
  grid-template-rows: repeat(1, 30px);
  grid-template-columns: 150px 150px;
  grid-gap: 5px;
`;
export const StateTagsWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SortButtons = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-items: center;
  outline: none;
  gap: 5px;
  flex: 1;

  @media (max-width: 600px) {
    order: -1;
  }
`;

export const ToggleButton = styled.button`
  cursor: pointer;
  background-color: ${(props) => (props.active ? '#1187B2' : '#0e4c60')};
  transition: background-color 0.3s;

  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
  margin: 0px;
  border-style: solid;
  border-width: ${(props) => (props.active ? '3px' : '1px')};
  border-color: ${(props) => (props.active ? '#FCD70B' : '#ffffff')};
  outline: none;
  align-items: center;
  height: 30px;
  color: white;
  // background-color: #0e4c60; /*#4c4b4c;*/

  &:hover {
    background-color: #3498db;
    color: #fff;
  }
`;

export const AddButton = styled.button`
  cursor: pointer;
  background-color: #0e4c60;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
  margin: 0px;
  border-style: solid;
  border-width: 1px;
  border-color: #ffffff;
  outline: none;
  align-items: center;
  height: 30px;
  width: 60px;
  color: white;

  &:hover {
    background-color: #3498db;
    color: #fff;
  }
`;

export const LawmakerTags = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;

  @media (max-width: 600px) {
    justify-content: center;
  }
`;

export const LawmakerTag = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid gray;
  border-radius: 6px;
  font-size: 12px;
  background-color: white;
  flex-shrink: 0;
`;

export const LawmakerContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 0.5rem;

  @media (max-width: 600px) {
    flex-direction: column;
	align-items: center;
  }
`;
