import React, { useEffect, useState } from "react";
import Chart from "react-google-charts";
import * as S from "./Story.Style";

const options = {
  width: 120,
  height: 120,
    redFrom:0,
    redTo:40,
    redColor:'#cc6666',
  greenFrom: 60,
  greenTo: 100,
    greenColor:'lightgreen',
  yellowFrom: 40,
  yellowTo: 60,
  minorTicks: 5,
  format: "percent",
};

function AlignmentMeters({ legOrgAlignmentData, orgs }) {
  const [selectedOrg, setSelectedOrg] = useState(orgs[0]);
  const [filteredDatas, setFilteredDatas] = useState(legOrgAlignmentData);

  useEffect(() => {
    if (selectedOrg === "") {
      let alignmentData = legOrgAlignmentData?.map(
        (data) => data["org_alignment"]
      );
      setFilteredDatas(alignmentData);
    } else {
      // filter for selected org & filter out Legislators with no data
      // first item is header (["Label", "Value"])
      let newData = legOrgAlignmentData
        .map((legOrgData) => {
          let data = legOrgData["org_alignment"]
            .slice(1)
            .filter((keyValPair) => keyValPair[0] === selectedOrg);
          let filteredData = [legOrgData["org_alignment"][0], data[data.length-1]];
          return {
            leg_name: legOrgData["leg_name"],
            org_alignment: filteredData,
            leg_last: legOrgData["leg_name"].split(' ').slice(-1).toString(),
          };
        })
        .filter(
          (legOrgData) =>
            legOrgData["org_alignment"].length > 1 &&
            !legOrgData["org_alignment"].includes(undefined)
        );
      setFilteredDatas(newData);
    }
  }, [selectedOrg]);

  return (
      <div>
          <S.BoxTitle>Alignment Meters</S.BoxTitle>
          <S.BoxNote>
              This display is a different look at the “alignments” included in the “Legislator-Interest Group Relations” chart above. The drop-down menu includes groups that have taken a position on this bill and the gauges show the voting alignment between the selected group and each legislator on the committee member.
          </S.BoxNote>
          <div className="small-gauges-alignment-meters">
              <select
                  style={{marginLeft: "20px", width:"calc(100% - 2rem)", marginBottom: "20px"}}
                  value={selectedOrg}
                  onChange={(e) => setSelectedOrg(e.target.value)}
              >
                  {orgs.length > 0 &&
                      orgs.toSorted().map((org) => <option value={org}>{org}</option>)}
              </select>

              {filteredDatas?.length > 0 &&
                  filteredDatas
                      .toSorted(function (a,b) {return (a['leg_last'] < b['leg_last']) ? -1 :
                          (a['leg_last'] > b['leg_last']) ? 1 : 0})
                      .map((data, idx) => {
                        return (
                          <span key={idx} className="gauge-display-small">
                              <Chart
                                  key={idx}
                                  chartType="Gauge"
                                  width="100%"
                                  height="120px"
                                  data={[
                                      [data["org_alignment"][0][0], data["org_alignment"][0][1]],
                                      [data["leg_last"], data["org_alignment"][1][1]]
                                  ]}
                                  options={options}
                              />
                          </span>
                        )
                  })
              }
          </div>
      </div>
  );
}

export default AlignmentMeters;
