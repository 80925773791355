import React, { useEffect, useState } from "react";
import {LinkSimple} from "../Stories/Links";
import {replaceLegCodes} from "../Story/Story";
import "../Story/Story.Style";
import "./TipSheet.css";

export const skipPhenoms = ['endofintro','intro','bill_digest','bill_ai_summary'];
export const ai_phenoms = ['bill_ai_summary'];

export default function TipSheetGeneral(props) {
    const [bullets, setBullets] = useState([]);
    const [ai_bullets,setAiBullets] = useState([]);

    useEffect(() => {                   // process bullet phenoms, store them in bulletData to render
        if (Array.isArray(props.props.content)) {    // if there is content
            let bulletData = [];              // stores bullets to be rendered
            let aiBulletData = [];
            props.props.content.forEach(phen => {    // for each phenom
                if (!skipPhenoms.includes(phen.phenom)) {       // if it's not in the exclusion list "skipPhenoms"
                    for (let tip in phen.tips) {                // for each tip in the phenom
                        if (phen.tips[tip].type === "bullet") { // if it is a bullet tip
                            let textContent = "";
                            if ((phen.tips[tip].hasOwnProperty('data')) &&
                                phen.tips[tip].data.length > 1 &&
                                phen.tips[tip].data !== "nan") {
                                textContent = replaceLegCodes(phen.tips[tip].data, props.props.personas);
                            }

                            if (phen.tips[tip].link_url?.length > 5){
                                if (phen.tips[tip].link_text?.length > 1) {
                                    textContent = (
                                        <>
                                            {textContent}
                                            ({LinkSimple(phen.tips[tip].link_text, phen.tips[tip].link_url,null,false)})
                                        </>
                                    )
                                }
                                else {
                                    textContent = (
                                        <>
                                            {textContent}
                                            ({LinkSimple('link', phen.tips[tip].link_url,null,false)})
                                        </>
                                    )
                                }
                            }
                            else
                                textContent = phen.tips[tip].data;

                            if (ai_phenoms.includes(phen.phenom)) {
                                aiBulletData.push({
                                    tip: textContent,
                                    score: phen.score,
                                    phenom: phen.phenom
                                });
                            }
                            else {
                                bulletData.push({
                                    tip:textContent,
                                    score:phen.score,
                                    phenom:phen.phenom
                                });
                            }
                        }

                    }
                }
            });
            setBullets(bulletData);
            setAiBullets(aiBulletData);
        }
    }, [props.props.content]);
    return (
    <div className="bullet_tips_li">
        {(bullets.length > 0) &&

        <ul><h3>based directly on primary sources</h3>
            {bullets.map((bullet, index) => (
                <li>
                    {bullet.tip} phenom: {bullet.phenom}, score: {bullet.score}
                </li>
            ))}
        </ul>
        }
    </div>
    );
}
