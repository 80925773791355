import useForm from '../../../../hooks/useForm';
export default function AccountInformation(props) {
    const { S, user, updateProfileWithoutPush ,setUserContext} = props;
    const { values, handleChange } = useForm({
        initialValues: {
            email: user.email,
            name: user.name,
            phone_number: user.phone_number,
            news_organization: user.news_organization
        }
    });
    const handleSubmit = async (e) => {
        document.getElementById('form-error').innerHTML = "";
        e.preventDefault(); 
        user.name = values.name;
        user.phone_number = values.phone_number;
        user.news_organization = values.news_organization;
        let result = await updateProfileWithoutPush(user);
        if(result ===true && document.getElementById('form-success')){
            setUserContext("no");
            document.getElementById('form-success').innerHTML = "Account Information Updated Successfully";
            window.setTimeout("document.getElementById('form-success').innerHTML='';", 3000);
        }
    }
    return (
        <S.Body>
            <S.BodyBox className="BodyBox">
                <div>
                    <S.BodyBoxTitle className="BodyBoxTitle">ACCOUNT INFORMATION</S.BodyBoxTitle>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="account-information-message-error" id="form-error">
                    </div>
                    <div style={{color:"green"}} className="account-information-message-success" id="form-success">
                    </div>
                    <div className="account-information-form">
                        <div className="account-information-form-group">
                            <label className="account-information-label">Name</label>
                            <input
                                className="account-information-form-control"
                                name="name"
                                type="text"
                                placeholder={""}
                                value={values.name}
                                onChange={handleChange} />
                        </div>
                        <div className="account-information-form-group">
                            <label className="account-information-label">Email</label>
                            <input
                                className="account-information-form-control"
                                type="text"
                                placeholder={""}
                                value={values.email}
                                onChange={handleChange}
                                disabled />
                        </div>
                        <div className="account-information-form-group">
                            <label className="account-information-label">Phone Number</label>
                            <input
                                className="account-information-form-control"
                                name="phone_number"
                                type="text"
                                placeholder={""}
                                value={values.phone_number}
                                onChange={handleChange} />
                        </div>
                        <div className="account-information-form-group">
                            <label className="account-information-label">Name of News Organization</label>
                            <input
                                className="account-information-form-control"
                                name="news_organization"
                                type="text"
                                placeholder={""}
                                value={values.news_organization}
                                onChange={handleChange} />
                        </div>
                    </div>
                    <div style={{"text-align": "center"}}>
                        <button className="button-account-information" type="submit">Update Information</button>
                    </div>
                </form>
            </S.BodyBox>
        </S.Body>
    );

}
