import React, { useEffect, useState } from "react";
import Sankey from "./Sankey";
import "./SliderSankey.css"

/**
 * Sankey component wrapper which adds a slider to control
 * the amount of left-hand (0th-index) items shown. The filtered data
 * takes the top (0th-index) items based on total of the given totalIndex values.
 */

export default function SliderSankey({ data, totalIndex, description = "" }) {
  const [keyTotalMap, setKeyTotalMap] = useState(null);
  const [sortedKeys, setSortedKeys] = useState([]);
  const [sliderValue, setSliderValue] = useState(1);
  const [filteredData, setFilteredData] = useState(data)

  useEffect(() => {
    // create map where keys are the 0th index property to reference later
    // skip first element (Google Charts Column Labels)
    let newMap = {};
    data.slice(1).forEach(element => {
      newMap[element[0]] = element[0] in newMap ? [...newMap[element[0]], element] : [element]
    });
    setKeyTotalMap(newMap)

    // total up totalIndex values & pair it with key
    let totals = {}
    Object.keys(newMap).forEach((key) => {
      totals[key] = newMap[key].reduce((acc, curr) => {
        return acc + curr[totalIndex]
      }, 0)
    })

    // sort descending based on totaled totalIndex values and return only the key
    let totalsTmp = Object.entries(totals).map(([k, v]) => [v, k])
    setSortedKeys(totalsTmp.sort((a, b) => b[0] - a[0]).map((val) => val[1]))

    setSliderValue(Math.min(3, Object.keys(totalsTmp).length))
  }, [data])

  useEffect(() => {
    if (!keyTotalMap) return;
    let filtered = [data[0]];
    for (let i = 0; i < sliderValue; i++) {
      filtered.push(...keyTotalMap[sortedKeys[i]])
    }
    setFilteredData(filtered)
  }, [sliderValue, keyTotalMap])

  return (
    <>
      <div className="slider-div">
        <label for="num-organizations">Number of {data[0][0]}s: </label>
        <span>{sliderValue}</span>
          <div className="slider">
            <span>1</span>
            <input type="range" id="num-organizations" name="num-organizations" min="1" max={keyTotalMap ? Object.keys(keyTotalMap).length : 1} value={sliderValue} onChange={(e) => setSliderValue(e.target.value)} />
            <span>{keyTotalMap ? Object.keys(keyTotalMap).length : 1}</span>
          </div>
        <div className="description">{description}</div>
      </div>
      <Sankey data={filteredData} />
    </>

  );
}
