const ExternalLinkIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={14}
    height={14}
    fill="none"
    {...props}
  >
    <path
      fill="#0A819F"
      d="M8 1c0-.531.438-1 1-1h3.969c.156 0 .281.031.406.094a.951.951 0 0 1 .313.218c.187.188.28.438.312.688v4c0 .563-.469 1-1 1-.563 0-1-.438-1-1V3.437L6.687 8.72a.964.964 0 0 1-1.406 0 .964.964 0 0 1 0-1.406L10.563 2H9c-.563 0-1-.438-1-1ZM0 3c0-1.094.875-2 2-2h3c.531 0 1 .469 1 1 0 .563-.469 1-1 1H2v9h9V9c0-.531.438-1 1-1 .531 0 1 .469 1 1v3c0 1.125-.906 2-2 2H2c-1.125 0-2-.875-2-2V3Z"
    />
  </svg>
)
export default ExternalLinkIcon
