import React, { useState } from 'react';
import { Chart } from 'react-google-charts';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import styled from 'styled-components';
import QuestionMarkIcon from '../TipSheetIcons/question-mark';

export const options = {
  isStacked: true,
  chartArea: { width: '100%' },
  colors: ['#028AD1', '#CC4839', '#D9D9D9'],
  hAxis: {
    minValue: 0,
    ticks: [],
  },
  vAxis: {
    textPosition: 'none',
  },
  annotations: {
    textStyle: {
      italic: true,
      color: 'black',
    },
    alwaysOutside: true,
    stem: {
      color: 'transparent',
      length: 0,
    },
  },
  bar: { groupWidth: '90%' },
  legend: { position: 'bottom' },
};

const BarChartWrapper = styled.div`
  position: relative;
`;

const BarChart = styled.div`
    margin: 0 2rem 0 4rem;
`;

const BarChartContainer = styled.div`
  font-weight: 700;
  line-height: 1.7;

  & :nth-child(1) {
    top: 25%;
  }

  & :nth-child(2) {
    top: 45%;
  }

  & :nth-child(3) {
    top: 66.67%;
  }
`;

const BarChartLabel = styled.span`
  position: absolute;
  left: 0;
`;

const BarChartVotes = styled.span`
  position: absolute;
  right: 0;
`;

const Tooltip = styled.div`
  background: #d9d9d9;
  padding: 1rem;
  max-width: 300px;
  border-radius: 0.25rem;
  margin-block-end: -1px;
  font-size: 0.625rem;
  font-style: italic;
  line-height: 1.7;
`;

const TooltipButton = styled.button`
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  border: none;
  padding: 0;
  background: none;
  position: absolute;
  top: calc(66.67% + 0.375rem);
  left: 2.25rem;
`;

// These labels match hardcoded values in getVotePartyData() in Story.jsx
const labels = ['AYE', 'NO', 'NVR'];

export default function StackedBar({ data }) {
  const { voterData, totalVotes } = data;
  const [isPopoverOpen, setPopoverOpen] = useState(false);

  if (!voterData.length > 0 || !totalVotes.length > 0) {
    return null;
  }

  return (
    <BarChartWrapper>
      <BarChart>
        <Chart
          chartType="BarChart"
          height="200px"
          data={voterData}
          options={options}
        />
      </BarChart>
      <BarChartContainer>
        {labels.map((label) => (
          <>
            <BarChartLabel key={label}>{label}</BarChartLabel>
            {label === 'NVR' ? (
              <Popover
                isOpen={isPopoverOpen}
                positions={['top', 'bottom', 'left', 'right']}
                onClickOutside={() => setPopoverOpen(false)}
                content={({ position, childRect, popoverRect }) => (
                  <ArrowContainer
                    position={position}
                    childRect={childRect}
                    popoverRect={popoverRect}
                    arrowColor={'#D9D9D9'}
                    arrowSize={16}
                  >
                    <Tooltip>“No Vote Recorded”</Tooltip>
                  </ArrowContainer>
                )}
              >
                <TooltipButton
                  type="button"
                  onClick={() => setPopoverOpen(!isPopoverOpen)}
                >
                  <QuestionMarkIcon />
                </TooltipButton>
              </Popover>
            ) : null}
          </>
        ))}
      </BarChartContainer>
      <BarChartContainer>
        {totalVotes.length > 0
          ? totalVotes.map((vote) => (
              <BarChartVotes key={vote}>({vote})</BarChartVotes>
            ))
          : null}
      </BarChartContainer>
    </BarChartWrapper>
  );
}
