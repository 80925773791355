import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import Header from './components/Header/Header';
import Home from './components/Home/Home';
import About from './components/About/About';
import Archive from './components/Archive/Archive';
import Staff from './components/Staff/Staff';
import Story from './components/Story/Story';
import Signup from './components/Account/Signup/Signup';
import Interests from './components/Account/Interests/Interests';
import Profile from './components/Account/Profile/Profile';
import Login from './components/Account/Login/Login';
import StoryRaw from './components/StoryRaw/StoryRaw';
import StoriesRaw from './components/StoriesRaw/StoriesRaw';
import { UserContext } from './hooks/UserContext';
import useFindUser from './hooks/useFindUser';
import { GoogleOAuthProvider } from '@react-oauth/google';

const PrivateRoute = ({ isLoading, children }) => {
  const authenticatedUser = localStorage.getItem('token');

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return authenticatedUser ? children : <Navigate to="/login" />;
};

// Route is used to navigate to the separate pages such as Homepage, About, Staff, Article etc
function App() {
  const { user, setUser, isLoading } = useFindUser();

  return (
    <GoogleOAuthProvider clientId="891898669780-734dqak2rq00s4ilock73mc60le86dlv.apps.googleusercontent.com">
      <Router>
        <UserContext.Provider value={{ user, setUser }}>
          <Header />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              path="/"
              element={
                <PrivateRoute isLoading={isLoading}>
                  <Home />
                </PrivateRoute>
              }
            />
            <Route
              path="/about"
              element={
                <PrivateRoute isLoading={isLoading}>
                  <About />
                </PrivateRoute>
              }
            />
            <Route
              path="/archive"
              element={
                <PrivateRoute isLoading={isLoading}>
                  <Archive />
                </PrivateRoute>
              }
            />
            <Route
              path="/staff"
              element={
                <PrivateRoute isLoading={isLoading}>
                  <Staff />
                </PrivateRoute>
              }
            />
            <Route
              path="/signup"
              element={
                <PrivateRoute isLoading={isLoading}>
                  <Signup />
                </PrivateRoute>
              }
            />
            <Route
              path="/interests"
              element={
                <PrivateRoute isLoading={isLoading}>
                  <Interests />
                </PrivateRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <PrivateRoute isLoading={isLoading}>
                  <Profile />
                </PrivateRoute>
              }
            />
            <Route
              path="/util/:storyid"
              element={
                <PrivateRoute isLoading={isLoading}>
                  <StoryRaw />
                </PrivateRoute>
              }
            />
            <Route
              path="/util"
              element={
                <PrivateRoute isLoading={isLoading}>
                  <StoriesRaw />
                </PrivateRoute>
              }
            />
            <Route
              path="/:storyid"
              element={
                <PrivateRoute isLoading={isLoading}>
                  <Story />
                </PrivateRoute>
              }
            />
          </Routes>
        </UserContext.Provider>
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;
