import lg from "../assets/images/Lindsay_Grace.webp";
import kt from "../assets/images/kt.jpg";
import fk from "../assets/images/fk.jpeg";
import foaad from "../assets/images/Foaad_Khosmood.png"
import sv from "../assets/images/srirag_vuppala.png"

const Staff = [
    {
        name: "Foaad Khosmood",
        title: "Associate Professor",
        University: "California Polytechnic State University",
        Photo: foaad,
        Description:
            'Foaad Khosmood (Persian: فواد خوشمود) is Forbes Professor of Computer Engineering and Associate Professor of Computer Science at California Polytechnic State University (Cal Poly). Foaad received a Ph.D. in Computer Science from the University of California Santa Cruz in 2011. His dissertation work was on "Computational Style Processing". His research interests include natural language processing (NLP), artificial intelligence, interactive entertainment, game AI and game jams. Dr. Khosmood is the Senior Research Fellow at the Institute for Advanced Technology & Public Policy.',
    },
    {
        name: "Lindsay Grace",
        title: "Professor",
        University: "University of Miami",
        Photo: lg,
        Description:
            " Lindsay is Knight Chair in Interactive Media and an associate professor at the University of Miami School of Communication. He is Vice President for the Higher Education Video Game Alliance and the 2019 recipient of the Games for Change Vanguard award. Lindsay's book, Doing Things with Games, Social Impact through Design, is a well-received guide to game design. In 2020, he edited and authored Love and Electronic Affection: a Design Primer on designing love and affection in games.",
    },
    {
        name: "Katy Huang",
        title: "UI Designer",
        University: "University of Miami",
        Photo: kt,
        Description:
            "Katy is a graduate student and assistant at the University of Miami School of Communication with a concentration in UX/UI design. With a background in art and advertising, her work has been published on Ads of the world, Graphis and Adstasher.",
    },
    {
        name: "Fehmi Karaagac",
        title: "Web Developer",
        University: "University of Miami",
        Photo: fk,
        Description:
            "Fehmi is a Web Developer at the University of Miami School of Communication with modern software development tools and technologies. He has Masters Degree in Electrical Engineering Science. He worked at Joint Strike Fighter (JSF) International Defense Program as a System Engineer.",
    },
    {
        name: "Srirag Vuppala",
        title: "Web Developer",
        University: "California Polytechnic State University",
        Photo: sv,
        Description:
            "Srirag is a student at California Polytechninc State University studying Computer Science. He is an open source enthusiast and has contributed to Project Jupyter's JupyterLab interface." 

    }
];

export default Staff;
