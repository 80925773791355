import styled from "styled-components";

export const HorizonTagsBarWrap = styled.div`
    width: 100%;
    gap: 0.5rem;
    display: flex;
    align-items: center;
    white-space: nowrap;
    flex-wrap: wrap;

	@media (max-width: 600px) {
		justify-content: center;
	}
`;

export const Img = styled.img`
    height: 16px;
`;

export const Tag = styled.div`
    display: inline-grid;
    grid-column-gap: 6px;
    grid-auto-flow: column;
    align-items: center;
    font-size: 12px;
    background-color: white;
    border: 1px solid gray;
    border-radius: 6px;
`;
