import React, { useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

// TODO: This is not needed, remove it
export default function StoriesRaw() {
    const [error, setError] = useState(null);
    let location = useLocation();
	const navigate = useNavigate();

    const handleExpiredToken = () => {
        console.log("Expired session token, redirecting to login.")
        localStorage.removeItem('token');
		navigate('/login', { state: { from: location } });
    };

    const handleOpenJson = async () => {
        let API_URL = `${process.env.REACT_APP_FEATURED_API}`;
        let axiosT = axios.create({
            baseURL: `${API_URL}`,
            headers: {
                'Authorization': `JWT ${localStorage.getItem('token')}`
            }
        });
        axiosT
            .get("/")
            .then((response) => {
                if (response.status !== 200) {
                    const errorDetails = {
                        status: response.status,
                    };
                    console.log("error error: ", response.status);
                    throw errorDetails;
                }
                const formattedJson = JSON.stringify(response.data.files);
                const blob = new Blob([formattedJson], { type: 'application/json' });
                const url = URL.createObjectURL(blob);
                window.open(url, '_blank');
            })
            .catch((err) => {
                if (err.status === 401) {
                    handleExpiredToken();
                }
                setError(err.status);
            })
    };

    if (error) return <div>API error, status {error}</div>;

    return (
        <div>
            <button onClick={handleOpenJson}>View JSON Data</button>
        </div>
    );
}
