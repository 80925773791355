const AiIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={17}
    fill="none"
    {...props}
  >
    <rect width={19.05} height={17} fill="#028AD1" rx={6.626} />
    <path
      fill="#fff"
      d="M5.668 12H4.03l2.495-7.228h1.97L10.986 12H9.35l-1.81-5.577h-.057L5.668 12Zm-.103-2.841h3.868v1.193H5.565V9.159Zm9.062-4.387V12H13.1V4.772h1.528Z"
    />
  </svg>
);
export default AiIcon;
