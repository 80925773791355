import React, { useState, useEffect, useContext } from "react";
import "../Profile/Profile.css";
import * as S from "./Profile.Style";
import tags from "../../../assets/Tags";
import { UserContext } from '../../../hooks/UserContext';
import CoverageTopic from './Partials/CoverageTopic';
import AccountInformation from './Partials/AccountInformation';
import useLogout from '../../../hooks/useLogout';
import useAuth from '../../../hooks/useAuth';
import profile from "../../../hooks/Profile";
import { CgProfile } from "react-icons/cg";
import { IoNewspaperOutline } from "react-icons/io5";
import { RiLogoutBoxLine } from "react-icons/ri";



export default function Profile() {
    const { getUserContext, setUserContext } = useAuth();
    const { updateProfile, updateProfileWithoutPush } = profile();
    const { logoutUser } = useLogout();
    const [getSelected, changeSelected] = useState("Account_Information")
    const { user } = useContext(UserContext);
    const [allTags, setAllTags] = useState([]);
    const [userInput, setUserInput] = useState([]);
    const handleCheckboxClick = (tag) => {
        userInput.includes(tag)
            ? setUserInput(...[userInput.filter((userInput) => userInput !== tag)])
            : setUserInput([...userInput, tag]);

    };

    const StringToArray = (string) => {
        if (string) {
            return string.split(',');
        }
        return [];
    }
    useEffect(() => {
        if (user) {
            let user_interests = StringToArray(user.user_interests);
            setUserInput(user_interests);
        }
        Promise.all(
            tags.map((icon) => import(`../../../assets/light_gray_icons/${icon}.svg`))
        ).then((svgs) => {
            const finalTags = [];
            svgs.map((svg) => {
                tags.map((icon) => {
                    svg.default.includes(icon) && finalTags.push({ svg, icon });
                });
            });

            setAllTags([...finalTags]);
        });
    }, []);
    return (
        <div >
            <S.ConatinerDiv>
                <S.LeftSidebarDiv>
                    <S.LeftSidebar>
                        <S.LeftHeadingDiv style={{ "text-transform": "capitalize" }}>
                            {user.name}
                        </S.LeftHeadingDiv>
                        <S.LeftOptionDiv className="left-menue-hover" style={{ "color": (getSelected == "Account_Information" ? 'black' : '') }} onClick={function () { changeSelected("Account_Information") }}>
                            <CgProfile className="menuIcons" />Account Information
                        </S.LeftOptionDiv>
                        <S.LeftOptionDiv className="left-menue-hover" style={{ "color": (getSelected == "CoverageTopic" ? 'black' : '') }} onClick={function () { changeSelected("CoverageTopic") }}>
                            <IoNewspaperOutline className="menuIcons" />Coverage Topics
                        </S.LeftOptionDiv>
                        <S.LeftOptionDiv className="left-menue-hover" onClick={logoutUser}>
                            <RiLogoutBoxLine className="menuIcons" />Sign Out
                        </S.LeftOptionDiv>
                    </S.LeftSidebar>
                </S.LeftSidebarDiv>
                {
                    {
                        'CoverageTopic': <CoverageTopic
                            setUserContext={setUserContext}
                            changeSelected={changeSelected}
                            setUserInput={setUserInput}
                            user={user}
                            S={S}
                            allTags={allTags}
                            userInput={userInput}
                            handleCheckboxClick={handleCheckboxClick}
                        />,
                        'Account_Information': <AccountInformation
                            changeSelected={changeSelected}
                            updateProfileWithoutPush={updateProfileWithoutPush}
                            S={S}
                            user={user}
                            getUserContext={getUserContext}
                            setUserContext={setUserContext} 
                        />,
                    }[getSelected]
                }
            </S.ConatinerDiv>
        </div>
    );
}
