// To be used on both tipsheet content and cards
import { Link } from "react-router-dom";

const base_url = "https://digitaldemocracy.calmatters.org";
const default_target = "_blank";
const entity_types = {'leg':'legislators','org':'organizations',
    'hea':'hearings','per':'people','bil':'bills','com':'committees','alt':'','other':''};

function __LinkDD(inText, inUrl, inKey = null, returnText=true) {
    if ((inText == null) || inUrl == null) {
        return null;
    }
    let newKey = inKey;
    if (inKey == null) {
        newKey = Date.now();
    }
    if (returnText) {
        return `<a href= ${inUrl} target=${default_target} key=${newKey}>${inText}</a>`;
	}

    return (<Link to={inUrl} key={newKey} target={default_target}>{inText}</Link>);
}

export function LinkSimple(inText,inUrl,inKey = null, returnText = true){
    return __LinkDD(inText,inUrl,inKey,returnText);
}

export function LinkDD(inText, id, inType, inKey = null, returnText = true, altText=null,suffix='') {
    let url = base_url;
    if (id && !isNaN(+id))
        id = id.toString();

    if (inType != null) {
        switch(inType) {
            case 'leg':
                url+= '/'+entity_types[inType]+'/'+(inText.toLowerCase()).split(' ').join('-')+'-'+id;
                break;
            case 'hea':
                url+='/'+entity_types[inType]+'/'+id+suffix;
                break;
            case 'other':
                break;
            default:
                url+= '/'+entity_types[inType]+'/'+id;
                break;
        }
        if(altText)
            return __LinkDD(altText,url,inKey,returnText);
        return __LinkDD(inText,url,inKey,returnText);
    }
}
