import axios from 'axios';
import useAuth from './useAuth';
import { useNavigate, useLocation } from 'react-router-dom';

export default function Profile() {

    const { setUserContext } = useAuth();
    let location = useLocation();
	const navigate = useNavigate();

    const handleExpiredToken = () => {
      console.log("Expired session token, redirecting to login.")
      localStorage.removeItem('token');
	  navigate('/login', { state: { from: location } });
    };

    const updateCoverageTopics = async(data)=>{
        let axisoT = axios.create({
            baseURL: `${process.env.REACT_APP_FEATURED_API}auth/profile/`
            ,
            headers: {
                'Authorization': `JWT ${localStorage.getItem('token')}`
            }
        });

        return axisoT.put(`coverage_topics/`,{"user_location_covered":data}).then(async (res) => {
            await setUserContext("no");
            return true;
        }).catch((err) => {
          handleExpiredToken();
        })
    };

    //update profile  
    const updateProfile = async (data) => {
      let axisoT = axios.create({
        baseURL: `${process.env.REACT_APP_FEATURED_API}auth/profile/`,
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      });

      return axisoT
        .put(`/`, data)
        .then(async (res) => {
          await setUserContext("/profile");
        })
        .catch((err) => {
          formatErrors(err);
          handleExpiredToken();
        });
    };

    const updateProfileWithoutPush = async (data) => {
      let axisoT = axios.create({
        baseURL: `${process.env.REACT_APP_FEATURED_API}auth/profile/`,
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      });

      return axisoT
        .put("/", data)
        .then(async (res) => {
          return true;
        })
        .catch((err) => {
          formatErrors(err);
          handleExpiredToken();
          return false;
        });
    };

    const toTitleCase = (phrase) => {
        return phrase
          .toLowerCase()
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
      };

    const formatErrors = (err) => {
        let messages= "";
        try {
            let keys = Object.keys(err.response.data)
            keys.forEach(bk => {
                let errorL = err.response.data[bk];
                errorL.forEach(ck => {
                    let key ='';
                    if(bk!=="non_field_errors"){
                        key =toTitleCase(bk.replaceAll('_',' '))+" : ";
                    }
                    messages +=key+ ck + "<br>";
                });
            });
        } catch (error) {
            messages = "Somthing bad happen";
        }
        document.getElementById('form-error').innerHTML = messages;
    }

    return {
        updateProfile,
        updateProfileWithoutPush,
        updateCoverageTopics
    }
}
