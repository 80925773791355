// need to figure out a more elegant way for this.
// dynamic, make it read from tsv if no svg use a "default"
// also get states from the tsv spreadsheet

const tags = [
    "aging",
    "agriculture",
    "arts",
    "budget",
    "business",
    "communication",
    "economy",
    "education",
    "elections",
    "employment",
    "entertainment",
    "environment",
    "finance",
    "healthcare",
    "housing",
    "insurance",
    "judiciary",
    "law",
    "medication",
    "public_safety",
    "rules",
    "sports",
    "taxations",
    "transportation",
    "utilities",
    "water",
    "wildlife",
    "women",
    "geo_los_angeles",
    "geo_fresno",
    "geo_bay_area",
];

export default tags;
