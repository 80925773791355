import styled from 'styled-components';
import { LinkDD } from '../Stories/Links';

const AlignmentWrapper = styled.div`
  padding: 2rem 0;
`;

const AlignmentTitle = styled.h2`
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.7;
  margin-block-start: 0;
`;

const AlignmentSubtitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.7;
`;

const AlignmentData = styled.div`
  display: flex;
  gap: 3rem;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const AlignmentDataWrapper = styled.div`
  flex: 1;
`;

const AlignmentDataContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-block-end: 1rem;
`;

const AlignmentDataNumber = styled.div`
  background-color: #d9d9d9;
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
  position: relative;

  & span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1;
  }
`;

const AlignmentDataHeading = styled.div`
  display: flex;
  flex-direction: column;

  & p {
    margin: 0;
  }
`;

const AlignmentDataTitle = styled.h4`
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5;
  margin: 0;
`;

const AlignmentScroll = styled.div`
  max-height: 500px;
  overflow-y: auto;
`;

const AlignmentScrollRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  gap: 1rem;
  align-items: center;

  &:nth-child(odd) {
    background-color: #d9d9d9;
  }
`;

const AlignmentScrollOrganization = styled.span`
  font-weight: 700;
  flex: 1;
  gap: 0.5rem;
  display: flex;
  align-items: center;

  & a {
    color: #000000;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  @media (max-width: 768px) {
    align-items: baseline;
    flex-direction: column;
  }
`;

const AlignmentScrollDonations = styled.span`
  text-align: right;
  flex: 1;
  white-space: nowrap;

  @media (max-width: 768px) {
    white-space: normal;
    text-align: left;
  }
`;

const AlignmentScrollBadge = styled.span`
  background-color: #000000;
  color: #ffffff;
  padding: 0.25rem 0.5rem;
  border-radius: 0.625rem;
  font-size: 0.625rem;
  font-weight: 700;
  line-height: 1;
  white-space: nowrap;
`;

const GreenText = styled.span`
  color: #47bf69;
`;

const RedText = styled.span`
  color: #cc4839;
`;

export const OrganizationAlignment = ({ story }) => {
  // Check if story is an empty array, does not contain the key 'assets', if 'assets' is an empty array, or if 'assets' does not contain the required id
  if (
    (Array.isArray(story) && story.length === 0) ||
    !story?.assets ||
    story.assets.length === 0 ||
    !story.assets.some(
      (type) => type.id === 'vote_alignment_org_contribution_success'
    )
  ) {
    return null;
  }

  const uniqueOids = new Set();
  const contributionValues = story.assets
    .find((type) => type.id === 'vote_alignment_org_contribution_success')
    .data.filter((item) => {
      if (!uniqueOids.has(item.oid)) {
        uniqueOids.add(item.oid);
        return true;
      }
      return false;
    });

  const donationTotalFor = story?.assets
    ?.find((type) => type.id === 'vote_alignment_org_contribution_success')
    ?.data?.filter(
      (item) => item.org_position === 'For' && item.donations !== 'N/A'
    )
    .map((item) => parseFloat(item.donations))
    .reduce((acc, item) => acc + item, 0);

  const donationTotalAgainst = story?.assets
    ?.find((type) => type.id === 'vote_alignment_org_contribution_success')
    ?.data?.filter(
      (item) => item.org_position === 'Against' && item.donations !== 'N/A'
    )
    .map((item) => parseFloat(item.donations))
    .reduce((acc, item) => acc + item, 0);

  const { forContributions, againstContributions } = contributionValues.reduce(
    (acc, item) => {
      if (item.org_position === 'For') {
        acc.forContributions.push(item);
      } else if (item.org_position === 'Against') {
        acc.againstContributions.push(item);
      }
      return acc;
    },
    { forContributions: [], againstContributions: [] }
  );

  const sortContributions = (contributions) => {
    return contributions.sort((a, b) => {
      const aValue =
        a.total_donated === 'N/A' ? -1 : parseFloat(a.total_donated);
      const bValue =
        b.total_donated === 'N/A' ? -1 : parseFloat(b.total_donated);

      if (aValue === -1 && bValue === -1) {
        return a.name.localeCompare(b.name);
      }

      if (aValue === -1) return 1;
      if (bValue === -1) return -1;

      return bValue - aValue;
    });
  };

  const sortedForContributions = sortContributions(forContributions);
  const sortedAgainstContributions = sortContributions(againstContributions);

  const calculateTotalDonated = (contributions) => {
    return contributions.reduce((total, item) => {
      const value =
        item.total_donated === 'N/A' ? 0 : parseFloat(item.total_donated);
      return total + value;
    }, 0);
  };

  const totalForContributions = calculateTotalDonated(sortedForContributions);
  const totalAgainstContributions = calculateTotalDonated(
    sortedAgainstContributions
  );

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(amount);
  };

  return (
    <AlignmentWrapper>
      <AlignmentTitle>Alignment</AlignmentTitle>
      <AlignmentSubtitle>Organizational Alignment</AlignmentSubtitle>
      <AlignmentData>
        <AlignmentDataWrapper>
          <AlignmentDataContainer>
            <AlignmentDataNumber>
              <span>{sortedForContributions.length}</span>
            </AlignmentDataNumber>
            <AlignmentDataHeading>
              <AlignmentDataTitle>
                Organizations in <GreenText>Support</GreenText>
              </AlignmentDataTitle>
              <p>
                Total $ given to legislators:{' '}
                {formatCurrency(totalForContributions.toFixed(2))} [2015 to
                Present]
              </p>
              <p>
                Total $ given to legislators of this committee:{' '}
                {formatCurrency(donationTotalFor.toFixed(2))} [2015 to Present]
              </p>
            </AlignmentDataHeading>
          </AlignmentDataContainer>
          <AlignmentScroll>
            {sortedForContributions.map((item, i) => (
              <AlignmentScrollRow key={item.oid}>
                <AlignmentScrollOrganization>
                  {LinkDD(item.name, item.oid, 'org', null, false)}
                  {i === 0 ? (
                    <AlignmentScrollBadge>Top Donor</AlignmentScrollBadge>
                  ) : null}
                </AlignmentScrollOrganization>
                <AlignmentScrollDonations>
                  {item.total_donated === 'N/A'
                    ? 'N/A'
                    : formatCurrency(
                        parseFloat(item.total_donated).toFixed(2)
                      )}{' '}
                  {item.total_donated_time_period
                    ? '[' + item.total_donated_time_period + ']'
                    : ''}
                </AlignmentScrollDonations>
              </AlignmentScrollRow>
            ))}
          </AlignmentScroll>
        </AlignmentDataWrapper>
        <AlignmentDataWrapper>
          <AlignmentDataContainer>
            <AlignmentDataNumber>
              <span>{sortedAgainstContributions.length}</span>
            </AlignmentDataNumber>
            <AlignmentDataHeading>
              <AlignmentDataTitle>
                Organizations <RedText>Against</RedText>
              </AlignmentDataTitle>
              <p>
                Total $ given to legislators:{' '}
                {formatCurrency(totalAgainstContributions.toFixed(2))} [2015 to
                Present]
              </p>
              <p>
                Total $ given to legislators of this committee:{' '}
                {formatCurrency(donationTotalAgainst.toFixed(2))} [2015 to
                Present]
              </p>
            </AlignmentDataHeading>
          </AlignmentDataContainer>
          <AlignmentScroll>
            {sortedAgainstContributions.map((item, i) => (
              <AlignmentScrollRow key={item.oid}>
                <AlignmentScrollOrganization>
                  {LinkDD(item.name, item.oid, 'org', null, false)}
                  {i === 0 ? (
                    <AlignmentScrollBadge>Top Donor</AlignmentScrollBadge>
                  ) : null}
                </AlignmentScrollOrganization>
                <AlignmentScrollDonations>
                  {item.total_donated === 'N/A'
                    ? 'N/A'
                    : formatCurrency(
                        parseFloat(item.total_donated).toFixed(2)
                      )}{' '}
                  {item.total_donated_time_period
                    ? '[' + item.total_donated_time_period + ']'
                    : ''}
                </AlignmentScrollDonations>
              </AlignmentScrollRow>
            ))}
          </AlignmentScroll>
        </AlignmentDataWrapper>
      </AlignmentData>
    </AlignmentWrapper>
  );
};
