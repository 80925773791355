import React from 'react';
import logo from '../../../assets/images/calmatters.jpg';
import background from '../../../assets/images/background.jpg';
import { jwtDecode } from "jwt-decode";

import * as S from '../Account.Style';
import '../Account.css';
import useAuth from '../../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';

export default function Login() {
	const navigate = useNavigate();

  const { loginUser, error } = useAuth();


  return (
    <div
      className="backgroundDiv"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="account-brand">
        <S.Brand
          onClick={function () {
            navigate('/');
          }}
        >
          <S.MenuLink to="#">
            <S.Logo src={logo} alt="AI TipSheets" />
          </S.MenuLink>

          <S.MenuLink to="#">
            <S.Name>AI TipSheets by CalMatters</S.Name>
          </S.MenuLink>
        </S.Brand>
      </div>
      <div style={{ display: 'flex' }}>
        <div className="account-container">
          <div className="account-message" id="form-error"></div>
          <h3>Sign In with Google</h3>
          <div className="google-button">
            <GoogleLogin
              onSuccess={(credentialResponse) => {
                const decodedToken = jwtDecode(credentialResponse.credential);
                const profilePicture = decodedToken.picture;
				localStorage.setItem('profilePicture', profilePicture);
                loginUser(
                  credentialResponse.credential,
                  credentialResponse.clientId
                );
              }}
              onError={() => {
                console.log('Login Failed');
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
