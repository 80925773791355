import aging from "./light_gray_icons/aging.svg";
import agriculture from "./light_gray_icons/agriculture.svg";
import arts from "./light_gray_icons/arts.svg";
import budget from "./light_gray_icons/budget.svg";
import business from "./light_gray_icons/business.svg";
import communication from "./light_gray_icons/communication.svg";
import economy from "./light_gray_icons/economy.svg";
import education from "./light_gray_icons/education.svg";
import elections from "./light_gray_icons/elections.svg";
import employment from "./light_gray_icons/employment.svg";
import entertainment from "./light_gray_icons/entertainment.svg";
import environment from "./light_gray_icons/environment.svg";
import finance from "./light_gray_icons/finance.svg";
import healthcare from "./light_gray_icons/healthcare.svg";
import housing from "./light_gray_icons/housing.svg";
import insurance from "./light_gray_icons/insurance.svg";
import judiciary from "./light_gray_icons/judiciary.svg";
import law from "./light_gray_icons/law.svg";
import medication from "./light_gray_icons/medication.svg";
import public_safety from "./light_gray_icons/public_safety.svg";
import rules from "./light_gray_icons/rules.svg";
import sports from "./light_gray_icons/sports.svg";
import taxations from "./light_gray_icons/taxations.svg";
import transportation from "./light_gray_icons/transportation.svg";
import utilities from "./light_gray_icons/utilities.svg";
import water from "./light_gray_icons/water.svg";
import wildlife from "./light_gray_icons/wildlife.svg";
import women from "./light_gray_icons/women.svg";

const tagsPath = {
  aging: aging,
  agriculture: agriculture,
  arts: arts,
  budget: budget,
  business: business,
  communication: communication,
  economy: economy,
  education: education,
  elections: elections,
  employment: employment,
  entertainment: entertainment,
  environment: environment,
  finance: finance,
  healthcare: healthcare,
  housing: housing,
  insurance: insurance,
  judiciary: judiciary,
  law: law,
  medication: medication,
  public_safety: public_safety,
  rules: rules,
  sports: sports,
  taxations: taxations,
  transportation: transportation,
  utilities: utilities,
  water: water,
  wildlife: wildlife,
  women: women,
};

export default tagsPath;
