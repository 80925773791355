import React, { useState, useEffect, useContext } from 'react';
import logo from '../../../assets/images/AI4R_logo.png';
import * as S from '../Interests/Interests.Style';
import '../Interests/Interests.css';
import tags from '../../../assets/Tags';
import profile from '../../../hooks/Profile';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../hooks/UserContext';

export default function Interests() {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const { updateProfile } = profile();
  const [userInput, setUserInput] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const handleCheckboxClick = (tag) => {
    userInput.includes(tag)
      ? setUserInput(...[userInput.filter((userInput) => userInput !== tag)])
      : setUserInput([...userInput, tag]);
  };
  const ArrayToString = (array) => {
    return array.toString();
  };
  const StringToArray = (string) => {
    if (string) {
      return string.split(',');
    }
    return [];
  };
  const handleSubmit = async (e) => {
    let user_interests = ArrayToString(userInput);
    user.user_interests = user_interests;
    await updateProfile(user);
  };
  const handleSkip = async (e) => {
    navigate('/');
  };
  useEffect(() => {
    if (user) {
      let user_interests = StringToArray(user.user_interests);
      setUserInput(user_interests);
    }
    Promise.all(
      tags.map((icon) => import(`../../../assets/light_gray_icons/${icon}.svg`))
    ).then((svgs) => {
      const finalTags = [];
      svgs.map((svg) => {
        tags.map((icon) => {
          svg.default.includes(icon) && finalTags.push({ svg, icon });
        });
      });

      setAllTags([...finalTags]);
    });
  }, []);
  return (
    <div>
      <div className="account-brand">
        <S.Brand>
          <S.MenuLink to="#">
            <S.Logo src={logo} alt="AI4Reporters" />
          </S.MenuLink>

          <S.MenuLink to="#">
            <S.Name>AI 4 REPORTERS</S.Name>
          </S.MenuLink>
        </S.Brand>
      </div>
      <div style={{ display: 'flex' }}>
        <S.Conatiner>
          <h2>Choose Interests</h2>
          <S.FilterDiv>
            <S.FilterBoxes>
              {allTags.map(({ svg, icon }, i) => {
                return (
                  <S.MenuItem key={i}>
                    <label
                      className="check-container"
                      style={{ 'text-align': 'left' }}
                    >
                      {icon === 'public_safety'
                        ? 'Public Safety'
                        : icon[0].toUpperCase() + icon.substring(1)}
                      <input
                        name="interest_checkbox[]"
                        type="checkbox"
                        checked={userInput.includes(icon)}
                        onChange={() => {
                          handleCheckboxClick(icon);
                        }}
                        id="option"
                      />
                      <span className="checkmark"></span>
                    </label>
                  </S.MenuItem>
                );
              })}
            </S.FilterBoxes>
            <S.StateTags>
              <S.StateTagsWrap>
                <label
                  className="check-container"
                  style={{ 'text-align': 'left' }}
                >
                  California
                  <input
                    value="California"
                    name="state_checkbox[]"
                    type="checkbox"
                    checked={isChecked2}
                    onChange={(event) =>
                      setIsChecked2(event.currentTarget.checked)
                    }
                  />
                  <span className="checkmark"></span>
                </label>
              </S.StateTagsWrap>
              <S.StateTagsWrap>
                <label
                  className="check-container"
                  style={{ 'text-align': 'left' }}
                >
                  Florida
                  <input
                    value="Florida"
                    name="state_checkbox[]"
                    type="checkbox"
                    onChange={(event) =>
                      setIsChecked(event.currentTarget.checked)
                    }
                    checked={isChecked}
                  />
                  <span className="checkmark"></span>
                </label>
              </S.StateTagsWrap>
            </S.StateTags>
          </S.FilterDiv>
          <button
            onClick={handleSubmit}
            className="filter-button"
            type="button"
          >
            Submit
          </button>
          <a className="skip-button" onClick={handleSkip}>
            Skip For Now
          </a>
        </S.Conatiner>
      </div>
    </div>
  );
}
