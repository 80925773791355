import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./CustomCarousel.css"

// TODO: 1 carousel library remove the others

const CustomCarousel = ({ components }) => {
    const numberOfSlides = components.length;

    const settings = {
        dots: true,
        infinite: numberOfSlides > 1,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        appendDots: dots => (
            <div style={{ overflow: 'hidden' }}>
                <ul style={{ display: 'flex', flexWrap: 'nowrap', whiteSpace: 'nowrap', overflowX: 'auto',
                    justifyContent: 'center'
                 }}>
                    {dots}
                </ul>
            </div>
        ),
    };

    const carouselStyle = {
        width: '100%',
    };

    return (
        <div style={carouselStyle}>
             <Slider {...settings}>
                {components?.map((component, index) => (
                    <div key={index}>
                        {component}
                    </div>
                ))}
            </Slider>
        </div>
       
    );
};

export default CustomCarousel;
